import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ISubscription} from 'rxjs-compat/Subscription';
import {UserService} from '../../user/user.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-user-verify',
  templateUrl: './user-verify.component.html',
  styleUrls: ['./user-verify.component.css']
})
export class UserVerifyComponent implements OnInit, OnDestroy {
  token: string;
  routerSubscription: ISubscription;
  message: string;

  constructor(private route: ActivatedRoute, private userService: UserService) {
  }

  ngOnInit() {
    this.routerSubscription = this.route.params.subscribe(params => {
      this.token = params['token'];
      this.userService.verifyByToken(this.token)
        .pipe(first())
        .subscribe(
          data => {
            if (data.status === 200) {
              this.message = 'User verified successfully.';
            } else {
              this.message = 'User verification failed';
            }
          },
          error => {
            if (error.json().message.indexOf('already') !== -1) {
              this.message = 'User already verified';
            } else {
              this.message = 'User verification failed';
            }
          }
        );
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
