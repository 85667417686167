export class Owner {
  id: number;
  address: string;
  city: string;
  // country: string;
  email: string;
  incorporationDate: string;
  name: string;
  phone: string;
  registrationNumber: string;
  taxId: string;
  type: string;

  public static createOwnerFromServerResponse(ownerObj): Owner {
    const owner = new Owner();
    owner.id = ownerObj.id;
    owner.address = ownerObj.address;
    owner.city = ownerObj.city;
    // owner.country = ownerObj.country;
    owner.email = ownerObj.email;
    owner.incorporationDate = ownerObj.incorporation_date;
    owner.name = ownerObj.name;
    owner.phone = ownerObj.phone;
    owner.registrationNumber = ownerObj.registration_no;
    owner.taxId = ownerObj.tin;
    owner.type = ownerObj.type;
    return owner;
  }
}
