import {Injectable} from "@angular/core";
import {Headers, RequestOptions} from "@angular/http";

@Injectable({
  providedIn: 'root'
})
export class SharedService{

  jwt() {
    // create authorization header with jwt token
    let currentUserToken = localStorage.getItem('currentUserToken');
    if (currentUserToken) {
      let headers = new Headers({ 'Authorization': 'Bearer ' + currentUserToken });
      return new RequestOptions({ headers: headers });
    }
  }

}
