import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { ErrorboxComponent } from './errorbox/errorbox.component';
import { SearchByOwnerComponent } from './search-by-owner/search-by-owner.component';
import { SearchByLicenseCodeComponent } from './search-by-license-code/search-by-license-code.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SettingsComponent } from './user/settings/settings.component';
import { ListUsersComponent } from './users/list-users/list-users.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { UserLeftMenuComponent } from './user/user-left-menu/user-left-menu.component';
import { AdminLeftMenuComponent } from './admin/admin-left-menu/admin-left-menu.component';
import {AuthGuard} from './auth/auth.guard';
import {RoleGuard} from './auth/role.guard';
import {MatCheckboxModule, MatButtonToggleModule} from '@angular/material';
import {MatDialogModule} from '@angular/material';
import {UserDeleteDialogComponent} from './users/user-delete-dialog.component';
import { AdminSidenavComponent } from './admin-sidenav/admin-sidenav.component';
import { CountriesComponent } from './countries/countries.component';
import { AboutComponent } from './about/about.component';
import { UserVerifyComponent } from './users/user-verify/user-verify.component';
import { RegistrationComponent } from './users/registration/registration.component';
import { EmailWhitelistComponent } from './email-whitelist/email-whitelist.component';
import { EditEmailWhitelistComponent } from './edit-email-whitelist/edit-email-whitelist.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { StaticLicenseComponent } from './static-license/static-license.component';
import { DatePipe } from '@angular/common';
import {MatSelectModule} from "@angular/material/select";
import {StaticValuationComponent} from "./static-valuation/static-valuation.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    UserComponent,
    ErrorboxComponent,
    SearchByOwnerComponent,
    SearchByLicenseCodeComponent,
    ChangePasswordComponent,
    SettingsComponent,
    ListUsersComponent,
    EditUserComponent,
    UserLeftMenuComponent,
    AdminLeftMenuComponent,
    UserDeleteDialogComponent,
    AdminSidenavComponent,
    CountriesComponent,
    AboutComponent,
    UserVerifyComponent,
    RegistrationComponent,
    EmailWhitelistComponent,
    EditEmailWhitelistComponent,
    RegistrationSuccessComponent,
    StaticLicenseComponent,
    StaticValuationComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        HttpModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatSelectModule
    ],
  providers: [DatePipe, AuthGuard, RoleGuard],
  bootstrap: [AppComponent],
  entryComponents:[UserDeleteDialogComponent]
})
export class AppModule { }
