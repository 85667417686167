import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  password: string;
  repeatedPassword: string;

  constructor() { }

  ngOnInit() {
  }

  changePassword() {
    console.log('Changing password:  TODO')
  }

}
