import {Component, OnInit} from '@angular/core';
import {LicenseService} from '../user/license.service';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-static-license',
  templateUrl: './static-license.component.html',
  styleUrls: ['./static-license.component.css']
})
export class StaticLicenseComponent implements OnInit {
  breakpoint;
  license: any;
  countryCode: string;
  displayedColumns: string[] = ['key', 'value'];
  dataSource: object[];
  translateKey = {
    'code': {'name':'License Code'},
    'applicationCode': {'name':'Application Code'},
    'type': {'name':'Type'},
    'startDate': {'name':'Start Date', format: 'yyyy-MM-dd'},
    'expirationDate': {'name':'Expiration Date', format: 'yyyy-MM-dd'},
    'country': {'name': 'Country'},
    'name': {'name':'Holder'},
    'email': {'name':'Holder E-Mail'},
    'phone': {'name':'Holder Phone'},
    'city': {'name':'Holder City'},
    'address': {'name':'Holder Address'},
    'taxId': {'name':'Tax ID'}
  };
  activeLicense: boolean = false;
  error;

  constructor(private licenseService: LicenseService,
              private route: ActivatedRoute,
              private titleService: Title,
              private datePipe: DatePipe) {}

  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 599) ? 1 : 2;

    this.titleService.setTitle('View License - RD Customs Verification');
    this.route.params.subscribe(async params => {
      try {
        this.license = await this.licenseService.getLicenseByEncryptedCode(params.countryCode, params.encryptedLicenseCode);
      }catch (e){
        console.log(e)
        if (e.status && e.status == 404){
          this.license = null;
        }else {
          this.error = "Unable to connect server. Please try later"
        }
      }
      const dataSource = [];
      if (this.license) {
        this.licenseService.detectAlive(this.license)
        if (this.license.active)
          this.activeLicense = true;
        dataSource.push({key: 'Status', value: this.license['status']})
        for (let key in this.license) {
          if (key == 'owner') {
            for (const ownerKey in this.license[key]) {
              if (this.translateKey[ownerKey] == undefined) continue;
              dataSource.push({key: this.translateKey[ownerKey].name, value: this.license[key][ownerKey]});
            }
          } else {
            if (this.translateKey[key] == undefined) continue;
            if (this.translateKey[key].format && this.license[key])
              dataSource.push({
                key: this.translateKey[key].name, value:
                  this.datePipe.transform(this.license[key], this.translateKey[key].format)
              });
            else
              dataSource.push({key: this.translateKey[key].name, value: this.license[key]});
          }
        }
      }
      this.dataSource = dataSource;
      this.countryCode = params.countryCode;
    });
  }

  onResize($event) {
    this.breakpoint = ($event.target['innerWidth'] <= 599) ? 1 : 2;
  }
}
