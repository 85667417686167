import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../../User';
import {first} from 'rxjs/operators';
import {ISubscription} from 'rxjs-compat/Subscription';
import {Router} from '@angular/router';
import {UserService} from '../../user/user.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  email: string;
  password: string;
  repeatedPassword: string;
  firstname: string;
  lastname: string;
  errorMessage: string;
  upsertSubscription: ISubscription;

  @ViewChild('emailField') emailField;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {
  }

  register(){
    this.errorMessage = '';
    const role = 'user';
    if(this.emailField.hasError('email')){
      this.errorMessage = 'Email you entered is incorrect';
      return false;
    }
    if (this.password !== this.repeatedPassword) {
      this.errorMessage = 'Passwords do not match';
      return false;
    }
    const user = new User(this.email.trim(), this.firstname.trim(), this.lastname.trim(), false, this.password, role);
    this.upsertSubscription = this.userService.upsertUser(user, 'new')
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigateByUrl('/register/success');
        },
        error => {
          console.log(error)
          this.errorMessage = error._body;
        }
      );
  }

}
