import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { License } from '../License';
import { Owner } from '../Owner';
import { LicenseService } from '../user/license.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search-by-owner',
  templateUrl: './search-by-owner.component.html',
  styleUrls: ['./search-by-owner.component.css']
})
export class SearchByOwnerComponent implements OnInit {

  displayedColumns = ['code', 'type', 'status'];
  licenses: License[];
  owners: Owner[];
  ownerLicenses: object = {};
  @Input() ownerQuery: string;
  @Input() country;
  @Output() inputChanged = new EventEmitter<string>();
  searched: boolean;
  owner;

  constructor(private licenseService: LicenseService, private router: Router, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Search By Owner - RD Customs Verification');
    if (localStorage.getItem('selectedCountry')) {
      this.country = localStorage.getItem('selectedCountry');
    }
    if(localStorage.getItem('owners')){
      this.owners = JSON.parse(localStorage.getItem('owners'))
      this.ownerLicenses = JSON.parse(localStorage.getItem('ownerLicenses'))
    }
    if(localStorage.getItem('licenses')){
      this.licenses = JSON.parse(localStorage.getItem('licenses'))
    }
    this.searched = false;
  }

  ownerClick(owner) {
    this.licenses = this.ownerLicenses[owner.id];
    localStorage.setItem('licenses', JSON.stringify(this.licenses));
  }

  licenseClick(license) {
    this.router.navigate(['/customs-user/license'], { queryParams: { licenseCode: license.code }});
  }

  async search() {
    this.owners = [];
    this.licenses = [];
    this.ownerLicenses = {};
    localStorage.setItem('licenses', null);
    const licenses = await this.licenseService.searchLicensesByOwner(this.country, this.ownerQuery);
    licenses.forEach(l => {
      if (l.code) {
        this.licenseService.detectAlive(l)
        if (this.ownerLicenses[l.owner.id]) {
          this.ownerLicenses[l.owner.id].push(l);
        } else {
          this.ownerLicenses[l.owner.id] = [l];
        }
      }
    });
    localStorage.setItem('ownerLicenses', JSON.stringify(this.ownerLicenses));
    for (const om of Object.keys(this.ownerLicenses)) {
      this.owners.push(this.ownerLicenses[om][0].owner);
    }
    localStorage.setItem('owners', JSON.stringify(this.owners));
    this.searched = true;
  }

  inputChange($event){
    this.inputChanged.emit($event.target.value);
  }
}
