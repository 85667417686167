import {Injectable} from '@angular/core';
import {Headers, Http, RequestOptions, Response} from '@angular/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

import {User} from '../User';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  apiUrlBase: string = environment.apiBaseUrl;

  constructor(private http: Http, private router: Router) {
  }


  getCountriesList() {
    return this.http.get(this.apiUrlBase + 'countries',
      this.jwt()
    );
  }

  getLicenseCount(code){
    return this.http.get(this.apiUrlBase+code+'/count',
      this.jwt())
  }

  getLicenseList(code){
    return this.http.get(this.apiUrlBase+code+'/list',
      this.jwt())
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUserToken = localStorage.getItem('currentUserToken');
    if (currentUserToken) {
      const headers = new Headers({'Authorization': 'Bearer ' + currentUserToken});
      return new RequestOptions({headers: headers});
    }
  }
}
