import { Component, OnInit } from '@angular/core';
import { UserService} from '../../user/user.service';

import { User } from '../../User';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {
  displayedColumns = ['action', 'email', 'firstname', 'lastname'];
  public users = [];

  constructor(private userService: UserService, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Users List - RD Customs Verification');
    this.getUsers().then(users => {
      this.users = users;
    });
  }


  getUsers(): Promise<User[]> {
    return this.userService.getUsersList();
  }

}
