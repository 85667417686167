import { Component } from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogActions, MatDialogContent} from '@angular/material';

@Component({
  selector: 'user-delete-dialog',
  template: `
              <h1 mat-dialog-title>Delete</h1>
              <mat-dialog-content>
                Delete this record from database?
              </mat-dialog-content>
              <mat-dialog-actions>
                <button mat-raised-button (click)="delete()"  tabindex="3">Yes</button>
                <button mat-dialog-close mat-raised-button tabindex="2">No</button>
              </mat-dialog-actions>
              `
})
export class UserDeleteDialogComponent {
  constructor(public dialogRef: MatDialogRef<UserDeleteDialogComponent>) {}

  delete(): void {
    this.dialogRef.close('delete')
  }
}

