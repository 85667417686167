import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { Router } from '@angular/router'
import { environment} from '../../environments/environment';

import { License } from '../License';
import {SharedService} from "./shared.service";

@Injectable({
  providedIn: 'root'
})
export class ValuationService {

  constructor(private http: Http,
              private router: Router,
              private shared: SharedService) {}


  getValuationByEncryptedCode(countryCode: string, encryptedValuationCode: string): Promise<License> {
    return this.http.get(environment.apiBaseRdxUrl + countryCode+'/valuations?encryptedValuationCode=' + encryptedValuationCode,
      this.shared.jwt())
      .toPromise()
      .then(response => {
        return response.json()
      })
      .catch(error => this.handleError(error));
  }


  private handleError(error: any): Promise<any> {
    console.error('An error occured', error);
    if (error.status === 401) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
    }
    return Promise.reject(error.message || error);
  }

}
