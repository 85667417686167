import { Component, OnInit } from '@angular/core';
import {UserService} from '../user/user.service';

@Component({
  selector: 'app-email-whitelist',
  templateUrl: './email-whitelist.component.html',
  styleUrls: ['./email-whitelist.component.css']
})
export class EmailWhitelistComponent implements OnInit {
  whitelistedEmails: any;

  displayedColumns = ['action', 'domain'];

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.getEmailWhitelist().subscribe(data=>{
      this.whitelistedEmails = data.json();
      console.log(this.whitelistedEmails)
    })
  }

}
