import {Injectable} from '@angular/core';
import {Headers, Http, RequestOptions, Response} from '@angular/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

import {User} from '../User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: Http, private router: Router) {
  }

  getUsersList(): Promise<User[]> {
    return this.http.get(this.getUsersApiUrlBase(),
      this.jwt())
      .toPromise()
      .then(response => {
        const users = response.json();
        users.map((user) => {
          return new User(user.id, user.firstname, user.lastname, user.active);
        });
        return users;
      })
      .catch(error => this.handleError(error));
  }

  getUserById(id: string) {
    return this.http.get(this.getUsersApiUrlBase() +'/'+ id,
      this.jwt()
    );
  }

  upsertUser(user: User, mode: string) {
    return this.http.put(this.getUsersApiUrlBase() + '/upsert/' + mode,
      user,
      this.jwt()
    );
  }

  changeUserPassword(id: string, currentPassword: string, newPassword: string){
    return this.http.post(this.getUsersApiUrlBase() + '/change-password/' + id,
      {newPassword: newPassword, currentPassword: currentPassword},
      this.jwt()
    );
  }

  deleteUserById(id: string) {
    return this.http.delete(this.getUsersApiUrlBase() + '/' + id,
      this.jwt()
    );
  }

  deleteWhitelistByDomain(domain: string) {
    return this.http.delete(this.getUsersApiUrlBase() + '/email-whitelist/' + domain,
      this.jwt()
    );
  }

  verifyByToken(token: string) {
    return this.http.post(this.getUsersApiUrlBase() + '/verify/' + token,
      this.jwt()
    );
  }

  getEmailWhitelist(){
    return this.http.get(this.getUsersApiUrlBase() + '/email-whitelist',
      this.jwt()
    );
  }

  upsertEmailWhitelist(domain, mode){
    return this.http.post(this.getUsersApiUrlBase() + '/email-whitelist/'+mode,
      {domain: domain},
      this.jwt()
    );
  }


  private jwt() {
    // create authorization header with jwt token
    const currentUserToken = localStorage.getItem('currentUserToken');
    if (currentUserToken) {
      const headers = new Headers({'Authorization': 'Bearer ' + currentUserToken});
      return new RequestOptions({headers: headers});
    }
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occured', error);
    if (error.status === 401) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
    }
    return Promise.reject(error.message || error);
  }

  private getUsersApiUrlBase(): string {
    return `${environment.apiBaseUrl}users`;
  }

}
