import { Component, OnInit } from '@angular/core';
import {ISubscription} from '../../../../node_modules/rxjs-compat/Subscription';
import {first} from 'rxjs/operators';
import {UserService} from '../user.service';
import {AuthService} from '../../auth/auth.service';
import {Title} from '@angular/platform-browser';
import decode from 'jwt-decode';
import {User} from '../../User';
import {Router} from '@angular/router';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  resetPassword: string;
  repeatedResetPassword: string;
  currentPassword: string;
  resetErrorMessage =  '';
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  admin: boolean;
  errorMessage: string;
  upsertSubscription: ISubscription;

  constructor(private authService: AuthService, private userService: UserService, private titleService: Title, private router: Router) {
      this.email = decode(localStorage.getItem('currentUserToken')).id;
  }

  ngOnInit() {
  }

  resetPasswordHandler(){
    if (this.resetPassword !== this.repeatedResetPassword) {
      this.resetErrorMessage = 'passwords do not match';
      return false;
    }
    this.upsertSubscription = this.userService.changeUserPassword(this.email, this.currentPassword, this.resetPassword)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          if(this.authService.isAdmin()){
            this.router.navigateByUrl('/users');
          } else{
            this.router.navigateByUrl('/customs-user');
          }
        },
        error => {
          this.resetErrorMessage = JSON.parse(error._body).message;
        }
      );
  }

}
