import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../user/user.service';
import {UserDeleteDialogComponent} from '../users/user-delete-dialog.component';
import {first} from 'rxjs/operators';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-edit-email-whitelist',
  templateUrl: './edit-email-whitelist.component.html',
  styleUrls: ['./edit-email-whitelist.component.css']
})
export class EditEmailWhitelistComponent implements OnInit {
  mode: string;
  domain: string;
  errorMessage: string;

  constructor(private route:ActivatedRoute, private userService:UserService, private router:Router, private dialog: MatDialog) { }

  ngOnInit() {
    this.route.params.subscribe((params)=>{
      this.mode = params['id']
      this.domain = params['id']!=='new'?params['id']:''
    })
  }

  create(){
    this.userService.upsertEmailWhitelist(this.domain, this.mode)
      .subscribe(
        data=>{
          this.router.navigateByUrl('/email-whitelist')
        },
        error=>{
          this.errorMessage = error.json();
        })
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(UserDeleteDialogComponent, {
      height: '200px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'delete') {
        return false;
      }
      this.userService.deleteWhitelistByDomain(this.mode)
        .pipe(first())
        .subscribe(
          next => {
            this.router.navigateByUrl('/email-whitelist');
          },
          error => {
            this.errorMessage = JSON.parse(error._body).message;
          }
        );
    });
  }

}
