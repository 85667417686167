import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { Router } from '@angular/router'
import { environment} from '../../environments/environment';

import { License } from '../License';
import {SharedService} from "../service/shared.service";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  apiUrlBase: string = environment.apiBaseUrl;
  constructor(private http: Http,
              private router: Router,
              private shared: SharedService
              ) { }

  getLicenseByCode(countryCode: string, licenseCode: string): Promise<License> {
    return this.http.get(this.getLicenseApiUrlBase(countryCode) + '?licenseCode=' + licenseCode,
      this.shared.jwt())
      .toPromise()
      .then(response => {
        const licenses = response.json()
        if (!licenses[0] || !licenses[0].length) return null
        return License.createLincenseFromServerResponse(licenses[0][0])
      })
      .catch(error => this.handleError(error));
  }

  getLicenseByEncryptedCode(countryCode: string, encryptedLicenseCode: string): Promise<License> {
    return this.http.get(environment.apiBaseRdxUrl + countryCode+'/licenses?encryptedLicenseCode=' + encryptedLicenseCode,
      this.shared.jwt())
      .toPromise()
      .then(response => {
        const licenses = response.json()
        if (!licenses[0] || !licenses[0].length) return null
        return License.createLincenseFromServerResponse(licenses[0][0])
      })
      .catch(error => this.handleError(error));
  }

  searchLicensesByOwner(countryCode: string, ownerQuery: string): Promise<License[]> {
    return this.http.get(this.getLicenseApiUrlBase(countryCode) + '?owner=' + ownerQuery,
        this.shared.jwt())
      .toPromise()
      .then(response => {
        const licenses = response.json()
        if (!licenses) return null

        return licenses.map(License.createLincenseFromServerResponse)
      })
      .catch(error => this.handleError(error));
  }

  detectAlive(license){
    if (license) {
      license.active = ['ACTIVE LICENSE', 'UNDER REVIEW', 'SUSPENDED'].find(it => it == license['status'].toUpperCase());
    }
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occured', error);
    if (error.status === 401) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
    }
    return Promise.reject(error.message || error);
  }

  private getLicenseApiUrlBase(countryCode: string): string {
    return `${this.apiUrlBase}${countryCode}/licenses`;
  }
}
