import {Http} from '@angular/http';

export class Country {
  id: string
  name: string
  flag_url: string
  constructor(id: string, name:string){
    this.id = id
    this.name = name
    this.flag_url = 'https://www.countryflags.io/'+id.toLowerCase()+'/flat/32.png'
  }
}
