import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {first} from 'rxjs/operators';
import decode from 'jwt-decode';
import {ISubscription} from 'rxjs-compat/Subscription';
import {Title} from '@angular/platform-browser';
import {CountriesService} from '../countries/countries.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  email: string;
  password: string;
  errorMessage: string;
  loginSubscription: ISubscription;
  countries: any;
  mobile: boolean = false;


  constructor(private router: Router, private authService: AuthService, private titleService: Title, private countryService: CountriesService) {
    if (!authService.isLoggedIn()) {
      return;
    }
    if (authService.isAdmin()) {
      router.navigateByUrl('/admin');
    } else {
      router.navigateByUrl('/customs-user');
    }
  }

  ngOnInit() {
    this.onResize(null);
    this.titleService.setTitle('Login - RD Customs Verification');
    this.countryService.getCountriesList()
      .subscribe(
        data=>{
          this.countries = data.json();
          this.countries = this.countries.filter(country=>{
            return country.customs_enabled;
          })
        }
      )
  }

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  login() {
    this.loginSubscription = this.authService.login(this.email.trim(), this.password)
      .pipe(first())
      .subscribe(
        data => {
          const decoded = decode(data.json().token);
          if (decoded.role === 'admin') {
            this.router.navigateByUrl('/admin');
          } else {
            this.router.navigateByUrl('/customs-user');
          }
        },
        error => {
          this.errorMessage = JSON.parse(error._body).message;
        }
      );
  }

  onResize($event) {
    this.mobile = (screen.width <= 599) ? true : false;
  }


}
