import { Owner } from './Owner';

export class License {
  code: string;
  applicationCode: string;
  type: string;
  applicationDate: string;
  expirationDate: string;
  startDate: string;
  status: string;
  owner: Owner;
  country: string;

  public static createLincenseFromServerResponse(licenseObj): License {
    const license = new License();
    license.code = licenseObj.lic_code;
    license.applicationCode = licenseObj.app_code;
    license.type = licenseObj.type;
    license.status = licenseObj.status;
    license.applicationDate = licenseObj.application_date;
    license.expirationDate = licenseObj.expiry_date;
    license.startDate = licenseObj.start_date;
    license.country = licenseObj.country;
    license.owner = Owner.createOwnerFromServerResponse(licenseObj.owner);
    return license;
  }
}
