import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../user/user.service';
import {User} from '../../User';
import {first, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ISubscription} from 'rxjs-compat/Subscription';
import {Title} from '@angular/platform-browser';
import {MatDialog} from '@angular/material';
import {UserDeleteDialogComponent} from '../user-delete-dialog.component';

import decode from 'jwt-decode';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy {
  email: string;
  password: string;
  repeatedPasword: string;
  resetPassword: string;
  repeatedResetPassword: string;
  firstname: string;
  lastname: string;
  admin: boolean;
  errorMessage: string;
  resetErrorMessage: string;
  routerSubscription: ISubscription;
  userSubscription: ISubscription;
  upsertSubscription: ISubscription;
  mode: any;
  active: boolean;
  currentUserName: string;
  @ViewChild('emailField') emailField;

  constructor(private userService: UserService, private router: Router,
     private route: ActivatedRoute, private titleService: Title, private dialog: MatDialog) {
    const token = localStorage.getItem('currentUserToken');
    this.currentUserName = decode(token).id;
  }

  ngOnInit() {
    this.routerSubscription = this.route.params.subscribe(params => {
      const email = params['id'];
      if (email === 'new') {
        this.mode = 'new';
        this.active = true;
        this.titleService.setTitle('Create User - RD Customs Verification');
        return;
      }
      this.userSubscription = this.userService.getUserById(email)
        .pipe(first())
        .subscribe(
          data => {
            this.mode = 'edit';
            this.titleService.setTitle('Edit User - RD Customs Verification');
            const user = data.json();
            this.email = user.id;
            this.firstname = user.firstname;
            this.lastname = user.lastname;
            this.admin = user.role === 'admin';
            this.password = null;
            this.active = user.active;
          },
          error => {
            this.errorMessage = JSON.parse(error._body).message;
          }
        );
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.upsertSubscription) {
      this.upsertSubscription.unsubscribe();
    }
  }

  create() {
    this.errorMessage = '';
    const role = this.admin ? 'admin' : 'user';
    if(this.emailField.hasError('email')){
      this.errorMessage = 'Email you entered is incorrect';
      return false;
    }
    if (this.mode === 'new' && this.password !== this.repeatedPasword) {
      this.errorMessage = 'Passwords do not match';
      return false;
    }
    const user = new User(this.email.trim(), this.firstname.trim(), this.lastname.trim(), this.active, this.password, role);
    this.upsertSubscription = this.userService.upsertUser(user, this.mode)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigateByUrl('/users');
        },
        error => {
          this.errorMessage = error._body;
        }
      );
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(UserDeleteDialogComponent, {
      height: '200px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'delete') {
        return false;
      }
      this.userService.deleteUserById(this.email)
        .pipe(first())
        .subscribe(
          next => {
            this.router.navigateByUrl('/users');
          },
          error => {
            this.errorMessage = JSON.parse(error._body).message;
          }
        );
    });
  }

  resetPasswordHandler() {
    const role = this.admin ? 'admin' : 'user';
    const user = new User(this.email.trim(), this.firstname.trim(), this.lastname.trim(), this.active, this.resetPassword, role);
    if (this.resetPassword !== this.repeatedResetPassword) {
      this.resetErrorMessage = 'Passwords do not match';
      return false;
    }
    this.upsertSubscription = this.userService.upsertUser(user, this.mode)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigateByUrl('/users');
        },
        error => {
          this.resetErrorMessage = error._body;
        }
      );
  }
}

