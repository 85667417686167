import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-errorbox',
  templateUrl: './errorbox.component.html',
  styleUrls: ['./errorbox.component.css']
})
export class ErrorboxComponent implements OnInit {

  @Input()
  errorMessage: string;

  constructor() { }

  ngOnInit() {
  }

}
