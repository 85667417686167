import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import {ValuationService} from "../service/valuation.service";


@Component({
  selector: 'app-static-valuation',
  templateUrl: './static-valuation.component.html'
})
export class StaticValuationComponent implements OnInit {
  breakpoint;
  valuation: any;
  countryCode: string;
  displayedColumns: string[] = ['key', 'value'];
  dataSource: object[];
  error = null;
  translateKey = {
    'lic_code': {'name':'License Code'},
    'status': {'name':'Status'},
    'type': {'name':'Type'},
    'parcel_certificate': {'name': 'Certificate', 'node': 'certificate'},
    'date_created': {'name':'Date', format: 'yyyy-MM-dd'},
    'country': {'name': 'Country'},
    'owner': {'name':'Owner', 'node': 'name'},
    'destination': {'name':'Destination', 'node': 'full_name'},
    'asset': {'name':'Asset', 'node': 'name'},
    'total_value': {'name':'Total Value'},
    'currency_code': {'name':'Currency'},
  };

  constructor(private valuationService: ValuationService,
              private route: ActivatedRoute,
              private titleService: Title,
              private datePipe: DatePipe) {}

  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 599) ? 1 : 2;

    this.titleService.setTitle('View Valuation - RD Customs Verification');
    this.route.params.subscribe(async params => {
      try {
        this.valuation = await this.valuationService.getValuationByEncryptedCode(params.countryCode, params.encryptedValuationCode);
      }catch (e){
        console.log(e)
        if (e.status && e.status == 404){
          this.valuation = null;
        }else {
          this.error = "Unable to connect server. Please try later"
        }
      }
      const dataSource = [];
      if (this.valuation) {
        console.log(this.valuation);
        for (let key in this.valuation) {
          console.log(key, this.translateKey[key], this.valuation[key])
            if (this.translateKey[key] == undefined) continue;
            if (this.translateKey[key].format && this.valuation[key])
              dataSource.push({
                key: this.translateKey[key].name, value:
                  this.datePipe.transform(this.valuation[key], this.translateKey[key].format)
              });
            else {
              let val = this.translateKey[key].node ? this.valuation[key][this.translateKey[key].node]: this.valuation[key]
              dataSource.push({key: this.translateKey[key].name, value: val});
            }
        }
      }
      this.dataSource = dataSource;
      this.countryCode = params.countryCode;
    });
  }

  onResize($event) {
    this.breakpoint = ($event.target['innerWidth'] <= 599) ? 1 : 2;
  }

}
