import {Component, OnInit} from '@angular/core';
import {CountriesService} from './countries.service';
import {first, map} from 'rxjs/operators';
import {Country} from '../Country';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})
export class CountriesComponent implements OnInit {
  countries = [];
  displayedColumns = ['flag', 'id', 'name']

  constructor(private countryService: CountriesService) {
  }

  ngOnInit() {
    this.countryService.getCountriesList()
      .pipe(first())
      .subscribe(
        countries => {
          let countriesArr = countries.json()
          countriesArr = countriesArr.map(country=>{
            return new Country(country.id, country.name)
          })
          this.countries = countriesArr;
        }
      );
  }

}
