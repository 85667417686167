import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { License } from '../License';
import { LicenseService } from '../user/license.service';
import { ActivatedRoute } from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-search-by-license-code',
  templateUrl: './search-by-license-code.component.html',
  styleUrls: ['./search-by-license-code.component.css']
})
export class SearchByLicenseCodeComponent implements OnInit {

  license: License;
  @Input() licenseCode: string;
  @Input() country;
  @Output() inputChanged = new EventEmitter<string>();
  searched: boolean;


  constructor(private licenseService: LicenseService, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Search By License Code - RD Customs Verification');
    this.route.queryParams.subscribe(params => {
      if (params.licenseCode) {
        this.licenseCode = params.licenseCode;
        this.search();
      }
      if (localStorage.getItem('license') && !params.licenseCode) {
        this.license = JSON.parse(localStorage.getItem('license'));
      }
    });
    if (localStorage.getItem('selectedCountry')) {
      this.country = localStorage.getItem('selectedCountry');
    }
    this.searched = false;
  }
  async search() {
    this.license = await this.licenseService.getLicenseByCode(this.country, this.licenseCode);
    this.licenseService.detectAlive(this.license)
    localStorage.setItem('license', JSON.stringify(this.license))
    this.searched = true;
  }

  inputChange($event){
    this.inputChanged.emit($event.target.value);
  }
}
