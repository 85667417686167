import {Injectable} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import {AuthService} from './auth.service';
import decode from 'jwt-decode';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;

    const token = localStorage.getItem('currentUserToken');

    // decode the token to get its payload
    const tokenPayload = decode(token);

    if (!this.auth.isLoggedIn()) {
      this.router.navigate(['login']);
      return false;
    } else if (tokenPayload.role !== expectedRole) {
      this.router.navigate(['customs-user']);
      return false;
    }
    return true;
  }

}
