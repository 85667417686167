import {Injectable} from '@angular/core';
import {Headers, Http} from '@angular/http';
import {Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import decode from 'jwt-decode';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private headers = new Headers({'Content-Type': 'application/json'});

  /*
    private authChangedSource = new Subject<boolean>();
    authChanged = this.authChangedSource.asObservable();
  */

  constructor(private http: Http, private router: Router) {
  }

  login(email: string, password: string) {
    return this.http.post(environment.apiBaseUrl+'users/login',
      JSON.stringify({email: email, password: password}),
      {headers: this.headers})
      .pipe(map((response: any) => {
        const result = response.json();
        if (result && result.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(result.user));
          localStorage.setItem('currentUserToken', result.token);
        }
        return response;
      }));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occured', error);
    if (error.status === 401) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
    }
    return Promise.reject(error.message || error);
  }

  isLoggedIn() {
    return !!localStorage.getItem('currentUser');
  }

  isAdmin() {
    const token = decode(localStorage.getItem('currentUserToken'));
    if (token.role !== 'admin') {
      return false;
    }
    return true;
  }

  isUser() {
    const token = decode(localStorage.getItem('currentUserToken'));
    if (token.role !== 'user') {
      return false;
    }
    return true;
  }

  getCurrentUser() {
    const currentUserJson = localStorage.getItem('currentUser');
    if (!currentUserJson) {
      return null;
    }
    let currentUser;
    try {
      currentUser = JSON.parse(currentUserJson);
    } catch (e) {
      console.error('Could not parse current user json', e);
      return null;
    }
    return currentUser;
  }

  guardForEditor() {
    if (!this.hasEditorRights()) {
      this.navigateToLoginPage();
    }
  }

  guardForAdmin() {
    if (!this.hasAdminRights()) {
      this.navigateToLoginPage();
    }
  }

  hasEditorRights() {
    const user = this.getCurrentUser();
    if (!user) {
      return false;
    }
    if (user.access !== 'editor' && user.access !== 'admin') {
      return false;
    }
    return true;
  }

  hasAdminRights() {
    const user = this.getCurrentUser();
    if (!user) {
      return false;
    }
    if (user.access !== 'admin') {
      return false;
    }

    return true;
  }

  navigateToLoginPage() {
    // TODO: Returning page
    this.router.navigate(['/login']);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();

    // this.authChangedSource.next(false);
  }
}
