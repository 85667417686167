import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {CountriesService} from '../countries/countries.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  LICENSE_TAB_INDEX = 0;
  OWNER_TAB_INDEX = 1;
  selectedTabIndex: number;
  country: any;
  userInput = '';
  countries: any;
  mobile = false;
  selectedCountry;

  private selectTab(tab) {
    if (tab === 'owner') this.selectedTabIndex = this.OWNER_TAB_INDEX;
    else this.selectedTabIndex = this.LICENSE_TAB_INDEX;
  }

  constructor(private route: ActivatedRoute, private router: Router, private countryService: CountriesService) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.route.url.subscribe(url => {
          this.selectTab(url[url.length - 1].path);
        });
      }
    });
    if (localStorage.getItem('selectedCountry')) {
      this.country = localStorage.getItem('selectedCountry');
    }
    if (localStorage.getItem('userSearchInput')) {
      this.userInput = localStorage.getItem('userSearchInput');
    }

  }


  onTabClick(event) {
    localStorage.setItem('userSearchInput', this.userInput)
    if (event.index === 0) {
      this.router.navigateByUrl('/customs-user/license');
    } else {
      this.router.navigateByUrl('/customs-user/owner');
    }
  }

  onChange(country) {
    localStorage.setItem('selectedCountry', country);
  }

  onInputChanged($event){
    this.userInput = $event;
  }
  ngOnInit() {
    this.onResize(null);
    this.countryService.getCountriesList()
      .subscribe(
        data=>{
          this.countries = data.json();
          this.countries = this.countries.filter(country=>{
            return country.customs_enabled;
          })
          if (localStorage.getItem('selectedCountry')) {
            this.country = localStorage.getItem('selectedCountry');
          }else{
            this.country = this.countries[0].id;
            localStorage.setItem('selectedCountry', this.countries[0].id)
          }
        }
      )
  }

  onResize($event) {
    this.mobile = (screen.width <= 599) ? true : false;
  }
}
