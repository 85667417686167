export class User {
  id: string
  firstname: string
  lastname: string
  password: string
  role: string
  active: boolean

  constructor(id: string, firstname: string, lastname: string, active:boolean, password='', role=''){
    this.id = id
    this.firstname = firstname
    this.lastname = lastname
    this.password = password
    this.role = role
    this.active = active
  }
}
