import {AfterViewChecked, AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthService } from './auth/auth.service'

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit{
  loggedIn = false;
  constructor(private authService: AuthService,  private router: Router) {
    router.events.subscribe((val)=>{
      this.loggedIn = this.authService.isLoggedIn();
    })
  }

  settings() {
    this.router.navigateByUrl('/customs-user/settings')
  }

  ngAfterViewInit(){
  }

  logout() {
    this.authService.logout()
    this.router.navigateByUrl('/login')
  }
}
