import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {UserComponent} from './user/user.component';
import {ListUsersComponent} from './users/list-users/list-users.component';
import {EditUserComponent} from './users/edit-user/edit-user.component';
import {UserVerifyComponent} from './users/user-verify/user-verify.component';
import {SettingsComponent} from './user/settings/settings.component';
import {AuthGuard} from './auth/auth.guard';
import {RoleGuard} from './auth/role.guard';
import {CountriesComponent} from './countries/countries.component';
import {AboutComponent} from './about/about.component';
import {RegistrationComponent} from './users/registration/registration.component';
import {EmailWhitelistComponent} from './email-whitelist/email-whitelist.component';
import {RegistrationSuccessComponent} from './registration-success/registration-success.component';
import {EditEmailWhitelistComponent} from './edit-email-whitelist/edit-email-whitelist.component';
import {StaticLicenseComponent} from './static-license/static-license.component';
import {StaticValuationComponent} from "./static-valuation/static-valuation.component";

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 's/:countryCode/:encryptedLicenseCode', component: StaticLicenseComponent},
  {path: 'e/:countryCode/:encryptedValuationCode', component: StaticValuationComponent},
  {path: 'register', component: RegistrationComponent},
  {path: 'register/success', component: RegistrationSuccessComponent},
  {path: 'about', component: AboutComponent},
  {path: 'admin/settings', component: SettingsComponent, canActivate: [RoleGuard], data: {expectedRole: 'admin'}},
  {path: 'admin', component: ListUsersComponent, canActivate: [RoleGuard], data: {expectedRole: 'admin'}},
  {path: 'customs-user/settings', component: SettingsComponent, canActivate: [AuthGuard]},
  {path: 'customs-user', component: UserComponent, canActivate: [AuthGuard]},
  {path: 'customs-user/license', component: UserComponent, canActivate: [AuthGuard]},
  {path: 'customs-user/owner', component: UserComponent, canActivate: [AuthGuard]},
  {path: 'user/:id', component: EditUserComponent, canActivate: [RoleGuard], data: {expectedRole: 'admin'}},
  {path: 'user/verify/:token', component: UserVerifyComponent},
  {path: 'users', component: ListUsersComponent, canActivate: [RoleGuard], data: {expectedRole: 'admin'}},
  {path: 'countries', component: CountriesComponent, canActivate: [RoleGuard], data: {expectedRole: 'admin'}},
  {path: 'email-whitelist', component: EmailWhitelistComponent, canActivate: [RoleGuard], data: {expectedRole: 'admin'}},
  {path: 'email-whitelist/:id', component: EditEmailWhitelistComponent, canActivate: [RoleGuard], data: {expectedRole: 'admin'}},
  // otherwise redirect to home
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
